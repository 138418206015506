<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import { withLeadingSlash, withTrailingSlash } from 'ufo'
import type { UILinkProps } from '@design-system/components/UI/Link/UILink.props'

import { iconsMap } from '@design-system/components/Contact/ContactMethod.iconsMapping'
import type { IconType } from '@design-system/components/Contact/ContactMethod.iconsMapping'

const {
  cartContactUsSideSlide,
  cartContactUsNeedHelpSidebarSlide,
  bookAnAppointmentSidebar,
} = SIDEBAR_IDS

const { ts } = useI18n()
const route = useRoute()
const { helpContactUs } = useRouteHelper()
const { country, isHome, checkoutUrl } = useRouteHelper()
const { getIsCountryShoppable } = useCountryHelper()
const { dispatchActionContactUsEvent, dispatchActionContactCheckoutEvent } =
  useGAActionEvents()
const { data: contactMethods } = await useInfosWithTitle('contact-us-info')
const { isDialogOpen: isNeedHelpSidebarOpen } = useDialog(
  cartContactUsNeedHelpSidebarSlide
)
const { isDialogOpen: isBookAnAppointmentSidebarOpen } = useDialog(
  bookAnAppointmentSidebar
)
const isAnyNestedSidebarOpen = computed(
  () => isNeedHelpSidebarOpen.value || isBookAnAppointmentSidebarOpen.value
)

const { getAllContacts, openLink } = useContacts()
const { data: allContactMethods } = await getAllContacts('all-contact-methods')

const isCountryShoppable = getIsCountryShoppable(country)
const needHelpSidebarContent = reactive({
  title: '',
  body: '',
})

const contactMethodsLabel = computed(
  () => contactMethods.value?.content?.label ?? ''
)

const needHelp = computed(
  () =>
    contactMethods.value?.content?.listOfCTAs?.filter(item => {
      const hideInCountries = isCountryShoppable
        ? item?.hideInShoppableCountries
        : item?.hideInNonShoppableCountries

      const checkPages = hideInCountries?.some(page =>
        withTrailingSlash(route.path).includes(
          withLeadingSlash(withTrailingSlash(page))
        )
      )

      if (hideInCountries?.includes('all pages')) return false
      if (!checkPages) return true
      else if (checkPages) return false
      return !!hideInCountries?.length
    }) ?? []
)

const isCheckoutPage = computed(() => route.path.includes(checkoutUrl))

const setNeedHelpSidebarContent = (
  type: string,
  title: string,
  body: string
) => {
  if (type !== 'sidesheet') return
  needHelpSidebarContent.title = title
  needHelpSidebarContent.body = body
}

type Method = NonNullable<
  typeof allContactMethods.value
>['content']['methods'][0]

const handleInternalCtaClick = (method: Method) => {
  if (method?.cta?.internalCta?.link)
    openLink(method.cta?.internalCta?.link!, method.cta?.internalCta?.type)
  trackingClickEvent(method?.cta?.externalLink?.link ?? method?.name)
}

const trackingClickEvent = (title: string) => {
  if (isCheckoutPage.value) {
    dispatchActionContactCheckoutEvent('popup', title)
  } else {
    dispatchActionContactUsEvent()
  }
}
</script>

<template>
  <OrganismsSidebarSlide
    :id="cartContactUsSideSlide"
    :header-props="{
      titleText: $ts('contacts'),
    }"
    :force-open="isAnyNestedSidebarOpen"
    :blur-background="isHome"
  >
    <template #body>
      <div class="flex h-full flex-col justify-between">
        <div class="gap-xl mb-xl flex flex-col">
          <p
            v-if="contactMethodsLabel"
            class="text-light-6 whitespace-pre-wrap"
          >
            {{ contactMethodsLabel }}
          </p>

          <ul
            v-if="allContactMethods?.content.methods"
            class="flex flex-col gap-4"
          >
            <template
              v-for="method in allContactMethods?.content?.methods"
              :key="`contact-method-${method._meta.name}`"
            >
              <li
                v-if="
                  !method?.hideInAllCountries?.value?.sidebar &&
                  !method?.hideInCountry?.sidebar
                "
                class="flex items-center justify-between gap-2"
              >
                <component
                  :is="iconsMap[method.icon as IconType]"
                  v-if="method?.icon"
                  aria-hidden="true"
                  class="size-6"
                />
                <span class="text-book-6 grow"> {{ method?.name }}</span>

                <MoleculesLinkWrapper
                  anatomy="link"
                  :target="
                    method.cta?.externalLink?.link
                      ? method.cta?.externalLink?.target
                      : '_self'
                  "
                  :aria-label="method.cta?.ariaLabel"
                  :to="
                    method.cta?.externalLink?.link ||
                    method.cta?.internalCta?.link
                  "
                  :type="
                    !method.cta?.externalLink?.link &&
                    method.cta?.internalCta?.type !== 'slug'
                      ? 'button'
                      : 'a'
                  "
                  :external="!!method.cta?.externalLink?.link"
                  link-underline
                  @click="handleInternalCtaClick(method)"
                >
                  <template #label>
                    {{ method.cta?.label }}
                  </template>
                </MoleculesLinkWrapper>
              </li>
            </template>
          </ul>
        </div>

        <div class="mb-14 flex flex-col gap-3">
          <MoleculesLinkWrapper
            :aria-label="$ts('checkoutPage.thankYou.needHelp')"
            anatomy="link"
            :to="helpContactUs"
          >
            <template #label>
              {{ ts('checkoutPage.thankYou.needHelp') }}
            </template>
          </MoleculesLinkWrapper>
          <ul class="flex flex-col gap-3">
            <li
              v-for="{ title, body, cta, _meta } in needHelp"
              :key="_meta.name"
            >
              <UILink
                v-if="cta?.type"
                v-bind="cta as unknown as UILinkProps"
                :type="cta.type as CtaTypes"
                @click="
                  setNeedHelpSidebarContent(cta.type, title ?? '', body ?? '')
                "
              />
            </li>
          </ul>
        </div>
      </div>
      <CartContactUsNeedHelpSidebarSlide
        :id="cartContactUsNeedHelpSidebarSlide"
        v-bind="needHelpSidebarContent"
      />
      <BookAnAppointmentSidebar is-nested need-brand-selection />
    </template>
  </OrganismsSidebarSlide>
</template>
